import React, { Fragment } from 'react'
import { Navigation } from '@/components/Navigation'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import leaf from '@/images/leaf-footer.svg'
import { MinimalFooter } from '@/components/MinimalFooter'
import { graphql, HeadProps, PageProps } from 'gatsby'
import { uniqBy } from 'lodash'
import { Contact } from '@/components/Contact'
import { SEO } from '@/components/Seo'
import { productImage, QueryResult } from './christmas-wreaths'
import { ProductOverview } from '../components/ProductOverview'
import { useHomeRedirect } from '@/hooks/useHomeRedirect'

const priceMap = {
  price_1LyXUzLpHtamE7M1qd75iso7: 'https://buy.stripe.com/5kAeW9diE3axaSQcN3',
  price_1LyXO4LpHtamE7M1le2V53Ei: 'https://buy.stripe.com/3cs15j1zWbH35yw14n',
  price_1LxsPsLpHtamE7M1Dvw22Cb3: 'https://buy.stripe.com/9AQ5lz7Yk12paSQeVe',
  price_1Lw2SrLpHtamE7M1Z3d5eFfJ: 'https://buy.stripe.com/3cs5lzemI26t6CAbIY',
  price_1Lw2SrLpHtamE7M1YJ9mHpRh: 'https://buy.stripe.com/6oE01ffqMbH32mk7sH',
  price_1Lw1PpLpHtamE7M1UQh7CzzJ: 'https://buy.stripe.com/bIYaFTbaweTf1ig5kC',
  price_1M1bqtLpHtamE7M1l0ExeHqK: 'https://book.stripe.com/28o6pDemI5iF4us7sN',
}

export const Head = (props: HeadProps) => (
  <SEO
    title="Support Sheen Mount School"
    pathname={props.location.pathname}
    description="Buy Beautiful Christmas Wreaths and support your local school"
  />
)

export const Wreaths: React.FC<PageProps<QueryResult>> = ({ data }) => {
  useHomeRedirect()
  const products = uniqBy(
    data.products.nodes.map(({ product }) => product),
    'id',
  )
  const ticket = uniqBy(
    data.ticket.nodes.map(({ product }) => product),
    'id',
  )[0]

  const ticketPrices = (productId: string) =>
    data.ticket.nodes
      .filter(({ product }) => product.id === productId)
      .sort((a, b) => a.unit_amount + b.unit_amount)

  const ticketPriceRange = (productId: string) =>
    ticketPrices(productId)
      .map(({ unit_amount }) => unit_amount / 100)
      .sort((a, b) => a - b)
      .map((price) => `£${price}`)
      .join(' - ')

  const productPrices = (productId: string) =>
    data.products.nodes
      .filter(({ product }) => product.id === productId)
      .sort((a, b) => a.unit_amount + b.unit_amount)

  const productPriceRange = (productId: string) =>
    productPrices(productId)
      .map(({ unit_amount }) => unit_amount / 100)
      .sort((a, b) => a - b)
      .map((price) => `£${price}`)
      .join(' - ')

  return (
    <div className="relative overflow-hidden bg-background">
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-background pb-4 lg:w-full lg:max-w-2xl">
            <Navigation />
          </div>
        </div>
      </div>
      <main>
        <div className="bg-white">
          <div className="py-16 lg:mx-auto lg:max-w-7xl lg:px-8">
            <div className="text-center mx-4">
              <img className="h-16 object-contain mx-auto" src={leaf} alt="" />

              <h2 className="font-serif text-4xl tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                Christmas Wreaths
              </h2>
              <h4 className="font-serif text-xl tracking-tight my-8 text-gray-900 sm:text-5xl lg:text-2xl">
                Support Sheen Mount School. Profits from the sale of wreaths
                will go to Sheen Mount School PSA.
              </h4>
              <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
                All wreaths available available in medium and large sizes.
                Please get in touch if you have bespoke requirements.
              </p>
              <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
                Medium wreaths are 35-40cm in diameter and large wreaths are
                45-50cm in diameter.
              </p>
            </div>

            <div className="relative mt-12">
              <div className="relative -mb-6 w-full overflow-x-auto pb-6">
                <ul
                  role="list"
                  className="mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-x-0"
                >
                  {products.map((product) => (
                    <li
                      key={product.id}
                      className="inline-flex w-64 flex-col text-center lg:w-auto mb-8 group relative"
                    >
                      <div className="w-full overflow-hidden rounded-md bg-gray-200">
                        <GatsbyImage
                          image={productImage(product)}
                          alt={product.description}
                          className="h-full w-full object-cover object-center group-hover:opacity-75"
                        />
                      </div>
                      <div className="mt-6">
                        <h3 className="font-serif text-lg mt-1 font-semibold text-gray-900">
                          <ProductOverview
                            priceMap={priceMap}
                            product={product}
                            prices={productPrices(product.id)}
                          />
                        </h3>
                        <p className="mt-1 text-gray-600 text-sm">
                          {productPriceRange(product.id)}
                        </p>
                      </div>

                      <h4 className="sr-only">Available sizes</h4>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Contact background={true} />
      </main>

      <MinimalFooter />
    </div>
  )
}

export const query = graphql`
  query {
    ticket: allStripePrice(
      filter: {
        active: { eq: true }
        product: { id: { eq: "prod_MkgriTJ85pcAKx" } }
      }
    ) {
      nodes {
        id
        unit_amount
        type
        currency
        product {
          id
          images
          name
          description
          type
          localFiles {
            url
            childImageSharp {
              gatsbyImageData(
                width: 768
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        active
        nickname
      }
    }
    products: allStripePrice(
      filter: {
        product: { id: { ne: "prod_MkgriTJ85pcAKx" }, active: { eq: true } }
        active: { eq: true }
      }
    ) {
      nodes {
        id
        unit_amount
        type
        currency
        product {
          id
          images
          name
          description
          type
          localFiles {
            url
            childImageSharp {
              gatsbyImageData(
                width: 768
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        active
        nickname
      }
    }
  }
`
export default Wreaths
